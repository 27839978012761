import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useContext, useRef, useState, useEffect } from "react";
import React from "react"; 
import Createuser from "../src/Pages/Createuser/Createuser";
import "./App.css";
import Agreement from "./Pages/Agreement/Agreement";
import Coupon from "./Pages/Coupon/Coupon";
import Profile from "./Components/Profile";   
import CouponStaff from "./Pages/Coupon/StaffCoupon/CouponStaff";
import Home from "./Pages/Home/Home";
import ViewCoupon from "./Pages/Coupon/ViewCoupon"; 
import CompletedCoupon from "./Pages/Coupon/StaffCoupon/CompletedCoupon";

function App() {
  const dataContext = React.createContext();
  const [data, setData] = useState();

  useEffect(() => {}, []);

  return (
    <dataContext.Provider value={data}>
      <Router>
        <Switch>
          <Route path="/about"></Route>
          <Route path="/users"></Route>
          <Route path="/login"> 
          </Route>
            
          <Route path="/createuser">
            <Createuser />
          </Route>
          <Route path="/agreement">
            <Agreement />
          </Route>
          <Route path="/coupon">
            <Coupon /> 
          </Route>
          <Route path="/generatecoupons">
            <Coupon /> 
          </Route>
          <Route path="/viewcoupons">
            <ViewCoupon /> 
          </Route>
          <Route path="/completedcoupons">
            <CompletedCoupon />
          </Route>
          <Route path="/viewstaffcoupons"> 
            <CouponStaff /> 
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </dataContext.Provider>
  );
}

export default App;
