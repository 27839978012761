import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Agreement = () => {
  return (
    <div className="container">
      <div>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim
        nibh, tincidunt et quam sit amet, sagittis rhoncus magna. Vivamus
        aliquet metus ac purus egestas pellentesque. Etiam non posuere ligula.
        Aenean vel erat eget ligula euismod aliquam. Nam pulvinar nunc lacus, eu
        placerat nibh ullamcorper eget. Cras sit amet nulla rhoncus, eleifend
        orci non, dignissim justo. Vestibulum et aliquam dolor, quis tempor
        justo. Vivamus bibendum malesuada elit sit amet pretium. Cras vel velit
        tortor. Fusce posuere posuere mauris a sodales. Aliquam et congue
        mauris. Vestibulum pulvinar pellentesque faucibus. Aliquam egestas
        faucibus dui ut varius. Curabitur ut suscipit felis, sed bibendum odio.
        Morbi dapibus pellentesque est, ut laoreet nulla luctus vel. Morbi
        mattis pellentesque nulla, quis pharetra dolor ultrices pellentesque.
        Curabitur consectetur varius eros ultrices pellentesque. Vivamus
        ultrices augue ut leo lobortis sagittis. Vestibulum lorem felis,
        porttitor vitae porttitor eget, molestie a ipsum. Aliquam ullamcorper
        ornare quam at tincidunt. Suspendisse suscipit accumsan eros et
        faucibus. In ac faucibus sapien. Sed fringilla condimentum elit,
        interdum convallis quam mollis vitae. Maecenas iaculis leo eget nulla
        tempor faucibus. Morbi nunc est, porttitor eu molestie ut, fermentum eu
        quam. Nullam laoreet ullamcorper lorem, ac congue dolor ornare sed.
        Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
        cubilia curae; Morbi commodo rhoncus velit. Vivamus ipsum leo,
        vestibulum id enim a, iaculis pellentesque tellus. Morbi a odio posuere,
        mollis odio molestie, egestas turpis. Sed mattis neque vel sollicitudin
        cursus. Nulla varius justo eget sapien dignissim, id ultricies urna
        egestas. Donec in finibus lacus, vel semper lorem. Phasellus scelerisque
        enim fermentum tellus volutpat sagittis. Sed nec urna ac sapien semper
        ultrices. Maecenas ut semper arcu. Suspendisse sapien nisl, consequat
        sed laoreet vel, laoreet non ipsum. Lorem ipsum dolor sit amet,
        consectetur adipiscing elit. Vestibulum ac mauris eu ex faucibus
        facilisis vel non elit. Nunc ipsum lectus, aliquam nec consectetur
        venenatis, varius vitae velit. Etiam interdum ultricies turpis, ac
        luctus enim efficitur ac. Fusce egestas eleifend faucibus. Aenean quis
        massa nulla. Quisque eleifend justo vulputate, lobortis est et, interdum
        massa. Praesent semper elit vitae arcu lobortis, sit amet vehicula nibh
        sodales. Cras cursus quam mi, mollis finibus dui tempus at. Maecenas ac
        dictum erat. Suspendisse auctor quis mi at auctor. Quisque id urna diam.
        Sed quis nibh vel nulla accumsan condimentum eu a ante. Quisque lobortis
        nec massa et pretium. Nulla elit nisi, mattis a lectus volutpat, congue
        euismod orci. Praesent non diam aliquam libero porttitor auctor.
        Curabitur in tortor eget tortor pellentesque tempus. Sed feugiat blandit
        mi, vel congue ex vehicula imperdiet. Interdum et malesuada fames ac
        ante ipsum primis in faucibus. Lorem ipsum dolor sit amet, consectetur
        adipiscing elit. Cras imperdiet sollicitudin venenatis. Nam lobortis,
        quam eget placerat efficitur, massa tortor semper urna, ut vestibulum
        magna lacus eu ipsum. Ut nisi metus, suscipit vitae scelerisque sit
        amet, cursus vel libero. Donec vel neque sit amet elit congue convallis
        non id massa. Quisque sit amet eros vel ex condimentum malesuada in at
        est. Etiam placerat volutpat diam vel iaculis. Mauris ac pharetra risus.
        Donec placerat ac libero ac tristique. Cras dui nulla, iaculis sit amet
        hendrerit quis, dapibus eget orci. Morbi ullamcorper vitae libero sit
        amet condimentum. Donec suscipit pulvinar enim vitae cursus. Donec
        tempus non nibh ut facilisis. Sed vehicula lacus id risus iaculis, ut
        maximus turpis ornare. Morbi rhoncus magna vitae eros pharetra lobortis.
        Quisque rhoncus diam auctor magna lobortis convallis. Sed id justo
        aliquet, mollis velit in, viverra nulla. Sed lobortis placerat lobortis.
        Praesent ac neque felis. Quisque nec aliquet ligula, sit amet pharetra
        massa. Maecenas consectetur vel justo sed porttitor. Nulla venenatis
        pulvinar justo ut dignissim. Sed ultrices sit amet erat vitae accumsan.
        Vestibulum hendrerit orci sit amet metus faucibus, non laoreet risus
        auctor. Praesent porttitor vulputate quam, ac auctor lectus vestibulum
        a. Vivamus sollicitudin mollis augue eget mattis. Ut molestie libero in
        lacus rutrum pretium. Aenean in neque sit amet neque feugiat semper in a
        augue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
        vitae urna elit. Curabitur vel ante dapibus, malesuada mi non, convallis
        arcu. Mauris tempor ultricies magna, sed condimentum erat sodales id.
        Phasellus vel metus accumsan, pretium massa consequat, dapibus justo.
        Nullam eu dignissim neque. Mauris quis tincidunt nulla, in suscipit
        orci. Mauris ut felis est. Vivamus consequat libero a lectus
        consectetur, sodales aliquam metus pretium. Nullam laoreet viverra erat.
        Fusce sapien sapien, feugiat sed odio ut, malesuada dapibus velit.
        Pellentesque vulputate arcu mattis, rhoncus enim non, maximus nibh. Nunc
        ac cursus tellus. Nullam ut nibh eget velit lobortis elementum vel vel
        ipsum. Nam purus elit, blandit in aliquet sed, sodales ac dolor. In
        tristique posuere tellus. Orci varius natoque penatibus et magnis dis
        parturient montes, nascetur ridiculus mus. Sed eget congue massa. Nullam
        metus elit, rutrum sed sodales vitae, ultrices eu neque. Integer a
        libero tristique, aliquam risus sit amet, aliquam velit. Nulla ligula
        velit, luctus vitae augue a, accumsan tempus est. Nulla scelerisque
        vitae nulla at maximus. Donec in rutrum ante. Morbi in arcu sed tortor
        ullamcorper pharetra et a diam. Interdum et malesuada fames ac ante
        ipsum primis in faucibus. Vivamus cursus sodales accumsan.
      </div>
      <form>
        <div>
          <button type="submit"> Agree</button>
        </div>
        <div>
          <button type="submit">Do not Agree</button>
        </div>
      </form>
    </div>
  );
};

export default Agreement;
