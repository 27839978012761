import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CssBaseline from "@material-ui/core/CssBaseline";
import MenuIcon from "@material-ui/icons/Menu";
import AssignmentIcon from "@material-ui/icons/Assignment";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Fade from "@material-ui/core/Fade";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MenuItem from "@material-ui/core/MenuItem";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PageviewIcon from "@material-ui/icons/Pageview";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import AddBoxIcon from "@material-ui/icons/AddBox";
import firebase from "../Firebase";
import "./Navbar.css";
import Login from "../Pages/Login/Login";
import Signup from "../Pages/Login/Signup";
import Choose from "../Pages/Login/Choose";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  appba: {
    marginTop: -5,
    marginBottom: -10,
    backgroundColor: "#F2F4F3",
  },
  btncolor: {
    color: "#0A0908",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-100px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  profile: {
    color: "black",
    cursor: "pointer",
    marginLeft: "25px",
    marginTop: "5px",
  },
  menu: {
    marginTop: "45px",
  },
  list: {
    marginTop: "50px",
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  // root: {
  //   display: "flex",
  // },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  // menuButton: {
  //   marginRight: 36,
  // },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [opensignup, setOpensignup] = React.useState(false);
  const [data, setdata] = useState();
  const [role, setrole] = useState();
  const [refresh, setrefresh] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menut, setmenut] = useState(false);
  const theme = useTheme();

  const db = firebase.firestore()

  useEffect(() => {
    try {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setdata(user);
          db.collection("Planers").doc(user.email).get().then(res=>{
            if(res){
              setrole(res.data())
            }
          })
        } else {
          setdata();
        }
      });
    } catch (e) {
      if (e) {
        console.log(e);
      }
    }
  }, [refresh]);

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setmenut(open);
  };
  const list = () => (
    <>
      <div
        className={clsx(classes.list, classes.fullList)}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >







{role  && role.role === "admin"  ?  <>   <List>
          {[ "Create User", "View Coupons", "Completed Coupons"].map(
            (text, index) => (
              <ListItemLink
                href={
                  text === "Create User"
                    ? "/createuser"
                    : text === "Completed Coupons"
                    ? "/completedcoupons"
                    : text === "View Coupons"
                    ? "/viewstaffcoupons"
                    : null
                }
              >
                <ListItemIcon>
                  {text === "Create User" && <AddBoxIcon />}
                  {text === "Completed Coupons" && <AssignmentIcon />}
                  {text === "View Coupons" && <PageviewIcon />}
 
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemLink>
            )
          )}
          </List> 
          </>
          :null
          }











      {role  &&  role.role === "planner" ?     <List>
          {[   "Generate Coupons", "View Coupons"].map(
            (text, index) => (
              <ListItemLink
                href={
                  text === "Generate Coupons"
                    ? "/generatecoupons"
                    : text === "View Coupons"
                    ? "/viewcoupons"
                    : null
                }
              >
                <ListItemIcon> 
                  {text === "Generate Coupons" && <AssignmentIcon />}
                  {text === "View Coupons" && <PageviewIcon />}
 
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemLink>
            )
          )}
        </List>    : null}
        
        
        
        {role && role.role === "staff" ?   <List>
          {[ "View Coupons","Completed Coupons"].map(
            (text, index) => (
              <ListItemLink
                href={
                  text === "View Coupons"
                    ? "/viewstaffcoupons"
                    : text === "Completed Coupons"
                    ? "/completedcoupons"
                    : null
                }
              >
                <ListItemIcon>
                   
                  {text === "View Coupons" && <PageviewIcon />}
                  {text === "Completed Coupons" && <CheckCircleIcon />} 
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemLink>
            )
          )}
        </List>     : null }
        

     
        <Divider />
        <List>
          {["Profile", "Logout"].map((text, index) => (
            <ListItemLink
              href={text === "Profile" ? "/profile" : null}
              onClick={text === "Logout" ? handleOpen1 : null}
            >
              <ListItemIcon>
                {text === "Change Password" && <LockOpenIcon />}
                {text === "Profile" && <AccountCircleIcon />}
                {text === "Logout" && <ExitToAppIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemLink>
          ))}
        </List>
      </div>
    </>
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setOpensignup(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpen1 = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setrefresh(!refresh);
        window.location.href = "/";
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const handlesignup = () => {
    setOpensignup(true);
  };

  return (
    <div>
      <AppBar position="static" className={classes.appba}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <span className="nv_djh4">
              {" "}
              <a href="/">
                {" "}
                <img
                  src="\imgs\logo_trans.png"
                  className="nv_d28w"
                  alt="logo"
                />{" "}
              </a>
            </span> 
          </IconButton>
          <Typography variant="h6" className={classes.title}></Typography>
          <div className="nv_pdp3">
            {data ? (
              <>
                <div className="nv_jdjdd22"> 
                  <Drawer
                    variant="permanent"
                    anchor="left"
                    open={open} 
                    onClose={handleClose} 
                    className={clsx(classes.drawer, {
                      [classes.drawerOpen]: open,
                      [classes.drawerClose]: !open,
                    })}
                    classes={{
                      paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                      }),
                    }}
                  >
                    <div className={classes.toolbar}>
                      {open ? (
                        <IconButton onClick={handleClose}> 
                          {theme.direction === "rtl" ? (
                            <ChevronRightIcon />
                          ) : (
                            <ChevronLeftIcon />
                          )}
                        </IconButton>
                      ) : (
                        <IconButton onClick={handleOpen}>
                          <ChevronRightIcon />
                        </IconButton>
                      )}
                    </div>

                    {list()}
                  </Drawer>

                  <Button color="inherit" onClick={handleOpen1}>
                    <VpnKeyIcon className={classes.btncolor} />{" "}
                    <span className="nv_dki99"> Logout </span>
                  </Button>
                  <AccountCircleIcon
                    className={classes.profile}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  />
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className={classes.menu}
                  >
                    <MenuItem onClick={handleClose}>
                      <a href="/profile"> Profile </a>
                    </MenuItem>

                    <MenuItem onClick={handleOpen1}>Logout</MenuItem>
                  </Menu>
                </div>
              </>
            ) : (
              <>
                {" "}
                <Button color="inherit" onClick={handleOpen}>
                  <VpnKeyIcon className={classes.btncolor} />{" "}
                  <span className="nv_dki99"> Login </span>
                </Button>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open}>
                    <div className={classes.paper}>
                      <h2 id="transition-modal-title"></h2>
                      <p id="transition-modal-description">
                     
                        <Login />
                      </p>
                    </div>
                  </Fade>
                </Modal>
                <Button color="inherit" onClick={handlesignup}>
                  <AddCircleIcon className={classes.btncolor} />{" "}
                  <span className="nv_dki99"> Sign up </span>
                </Button>{" "}
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={opensignup}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={opensignup}>
                    <div className={classes.paper}>
                      <h2 id="transition-modal-title"> </h2>
                      <p id="transition-modal-description">
                        <Signup close={handleClose} />
                      </p>
                    </div>
                  </Fade>
                </Modal>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
     {role  && <div className = "nv_dj2od">  {role.business}</div> }  

    </div>
  );
};

export default Navbar;
