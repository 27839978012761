import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Buttons from "../../Components/Button";
import firebase from "../../Firebase";
import Alert from "@material-ui/lab/Alert";
import "./Changepwd.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  tt: {
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

const Changepwd = () => {
  const [opassword, setopassword] = useState("");
  const [newpassword, setnewpassword] = useState("");
  const [newpassword2, setnewpassword2] = useState("");
  const [pwderr, setpwderr] = useState("");
  const [alert, setalert] = useState(false);
  const [xstate, setxstate] = useState(false);

  const classes = useStyles();

  const changeHD = (e) => {
    let id = e.target.id;
    let value = e.target.value;

    switch (id) {
      case "opassx":
        setopassword(value);
        break;
      case "npassx":
        setnewpassword(value);
        break;
      case "npassx2":
        setnewpassword2(value);

        break;
      default:
        break;
    }
  };

  const offFocus = (e) => {
    if (newpassword === newpassword2) {
      document.getElementById("npassx2").style.color = "green";
      document.getElementById("npassx").style.color = "green";
    } else {
      document.getElementById("npassx2").style.color = "red";
    }
  };
  const actionx = async (e) => {
    const user = firebase.auth().currentUser;

    await firebase
      .auth()
      .signInWithEmailAndPassword(user.email, opassword)
      .then((userCredential) => {
        let userx = userCredential.user;

        if (userx) {
          if (newpassword === newpassword2) {
            user
              .updatePassword(newpassword)
              .then(function () {
                // Update successful.
                setxstate(true);
              })
              .catch(function (error) {
                setpwderr("New passwords not valid!");
                setalert(true);
              });
          } else {
          }
        }
      })
      .catch((e) => {
        if (e) {
          console.log(e);
          setpwderr("Old password is incorrect!");
          setalert(true);
        }
      });
  };

  return (
    <>
      {xstate ? (
        <div className="cp_dj22">
          <Alert severity="success">Password changed successfully</Alert>
        </div>
      ) : (
        <div>
          {alert && (
            <div className="cp_dj22">
              <Alert severity="error">{pwderr}</Alert>
            </div>
          )}

          <div>
            <TextField
              className={classes.tt}
              id="opassx"
              label="Old Password"
              type="password"
              variant="outlined"
              autoComplete="current-password"
              onChange={changeHD}
              value={opassword}
            />
          </div>
          <div>
            <TextField
              className={classes.tt}
              id="npassx"
              label="New Password"
              type="password"
              variant="outlined"
              autoComplete="current-password"
              onChange={changeHD}
              value={newpassword}
            />
          </div>
          <div>
            <TextField
              className={classes.tt}
              id="npassx2"
              label="Confirm New Password"
              type="password"
              variant="outlined"
              autoComplete="current-password"
              onChange={changeHD}
              value={newpassword2}
              onBlur={offFocus}
              onKeyUp={offFocus}
            />
          </div>
          <div className="cp_dbtn" >
            <Buttons name="Change Password" onClick={actionx} id = "Password" />
          </div>
          <div>
            <ul className="cp_liui">
              <li>Password should not be less than 6 chars</li>
              <li>Use Alpha-numeric combinations</li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Changepwd;
