import React from "react";
import Button from "@material-ui/core/Button";
import EmailIcon from '@material-ui/icons/Email';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import GetAppIcon from '@material-ui/icons/GetApp';
import PageviewIcon from '@material-ui/icons/Pageview';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from "@material-ui/core/styles";
import SettingsIcon from '@material-ui/icons/Settings';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PrintIcon from '@material-ui/icons/Print';
import "./Button.css"

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },

  btn: {
    backgroundColor: "#FF6110",
    color: "#F2F4F3",
    marginTop: "5px",
    marginBottom: "5px",
  },
  success:{
    backgroundColor: "rgb(48, 180, 48)",
    color: "#F2F4F3" ,
    marginTop: "5px",
    marginBottom: "5px",
  }
}));

const Buttons = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Button className={props.color? classes.success : classes.btn}  onClick={props.onClick} >
        {" "}
        
        {props.id === "SendMessage" ? <EmailIcon className = "bt_email"/>   : null} 
        {props.id === "Password" ? <LockOpenIcon className = "bt_email"/>   : null} 
        {props.id === "Download" ? <GetAppIcon className = "bt_email"/>   : null} 
        {props.id === "View" ? <PageviewIcon className = "bt_email"/>   : null} 
        {props.id === "Save" ? <SaveIcon className = "bt_email"/>   : null} 
        {props.id === "Generate" ? <SettingsIcon className = "bt_email"/>   : null} 
        {props.id === "Print" ? <PrintIcon className = "bt_email"/>   : null} 
        {props.id === "Back" ? <NavigateBeforeIcon className = "bt_email"/>   : null} 
        
        
        { props.name}
        {props.id === "Next" ? <NavigateNextIcon className = "bt_email"/>   : null} 
      </Button>
    </div>
  );
};

export default Buttons;
