import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import firebase from "../../Firebase";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert"

import "./Login.css";
import Buttons from "../../Components/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  tt: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  btn: {
    backgroundColor: "#FF6110",
    color: "#F2F4F3",
  },
  alert: {
    marginBottom:"25px",
    textAlign:"center"
  },
  cencen: {
    textAlign:"center"
  }
}));

const Login = (props) => {
  const classes = useStyles();
  const [err, seterr] = useState(false);
  const [errm, seterrm] = useState("");
  const [lg, setlg] = useState(false);
  const [lgm, setlgm] = useState("");
  const [data1, setdata1] = useState();
  const [userx, setuserx] = useState("");
  const [passx, setpassx] = useState("");
  const [show,setshow] = useState(false)
  const [rs,setrs] = useState('')
  const [rserr,setrserr] = useState(false)
  const [rserrmsg,setrserrmsg] = useState()
  const [ rssuc , setrssuc] = useState(false)  

  const username = useRef(null);
  const password = useRef(null);
  const xContext = useContext("dataContext");
  const [data, setdata] = useState();

  const db = firebase.firestore();

  useEffect(() => {
    try {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setlgm(JSON.stringify(user));
          setlg(true); 
          if (props.role === "Planners") {
            db.collection("Planers")
              .doc(user.email)
              .get()
              .then((doc) => { 
                if (doc.exists) {
                  setdata(doc.data());
                } else {
                  console.log("No doc present");
                }
              });
          } else {
            db.collection("Staff")
              .doc(user.email)
              .get()
              .then((doc) => {
                console.log(doc);
                if (doc.exists) {
                  setdata(doc.data());
                } else {
                  console.log("No doc present");
                }
              });
          }
        } else {
        }
      });
    } catch (e) {
      if (e) {
        console.log(e);
      }
    }
  }, []);
  const changeHD = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (id === "userx") {
      setuserx(value);
    } else if (id === "passx") {
      setpassx(value);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    await firebase
      .auth()
      .signInWithEmailAndPassword(userx, passx)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        seterr(false);
        setlgm(JSON.stringify(user));
        setlg(true);
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        seterrm(errorMessage);
        seterr(true);
      });
  };

  const cchangeHandler = e => {
    let value = e.target.value
    setrs(value)


  }

  const csubmitHandler = e=> {
    e.preventDefault()
    var auth = firebase.auth();
    auth.sendPasswordResetEmail(rs).then(function() {
      setrssuc(true)
      seterr(false)
      // Email sent.
    }).catch(function(error) {
      // console.log(error)
setrserr(true)
setrserrmsg(error.message)

});
  }

  const showme = () => {

    setshow(true)
  }

  if(show){
    return(
      <>
      {rssuc && <Alert severity="success" className = {classes.alert}>Reset Email Sent!</Alert>  }
      {rserr && <Alert severity="error" className = {classes.alert}> {rserrmsg} </Alert> } 
  <div className = {classes.cencen}> 
  
           <div>
             <TextField variant = "outlined" label = "Email Address" onChange = {cchangeHandler}  />

           </div>
           <div className = "lg_d38jd">
             <Buttons name = "Reset Password" onClick = {csubmitHandler} />
           </div>
           </div>

      </>
    )

  }
  else{
    return (
      <div className="lg_i33">
        <div> {err ? errm : null}</div>
        <div> 
          <TextField
            className={classes.tt}
            id="userx"
            label="Username"
            variant="outlined"
            onChange={changeHD}
            ref={username}
          />
        </div>
        <div> 
          <TextField
            className={classes.tt}
            id="passx"
            label="Password"
            type="password"
            variant="outlined"
            autoComplete="current-password"
            onChange={changeHD}
            ref={password}
          />
        </div>
        <Button
          variant="contained"
          onClick={submitHandler}
          className={classes.btn}
        >
          Log in
        </Button>
  
        <div className = "lg_djjjr22"  > 
        
   <span onClick = {showme} >  Reset your password    </span>     </div>
         
      </div>
    );

  }

  
};

export default Login;
