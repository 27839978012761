import React, { useState } from "react";
import EmailValidator from "email-validator";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
 
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";

import "./Signup.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  tt: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "300px",
  },
  btn: {
    backgroundColor: "#FF6110",
    color: "#F2F4F3",
  },
  cbox: {
    color: "#FF6110",
    "&$checked": {
      backgroundColor: "#FF6110",
    },
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: "#FF6110",
    "&$checked": {
      color: "#FF6110",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Signup = ({ close }) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    checkedA: false,
  });
  const [page1, setpage] = useState(1);
  const [businessx, setbusinessx] = useState("");
  const [rcx, setrcx] = useState("");
  const [emailx, setemailx] = useState("");
  const [phonex, setphonex] = useState("");
  const [addressx, setaddressx] = useState("");
  const [emailerror, setemailerror] = useState(false);
  const [showe, setshowe] = useState(false);
  const [showerr2, setshowerr2] = useState(false);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (state.checkedA === true) {
    }
  };

  const changeHandler = (e) => {
    let id = e.target.id;
    let value = e.target.value;
    switch (id) {
      case "businessx":
        setbusinessx(value);
        break;
      case "rcx":
        setrcx(value);
        break;
      case "emailx":
        if (EmailValidator.validate(value)) {
          setemailerror(false);
          setemailx(value);
          setshowe(false);
        } else {
          setemailerror(true);
        }

        break;
      case "phonex":
        setphonex(value);
        break;
      case "Addressx":
        setaddressx(value);
        break;

      default:
        break;
    }
  };
  const nextHandle = (e) => {
    e.preventDefault();
    if (
      emailerror ||
      emailx === "" ||
      businessx === "" ||
      rcx === "" ||
      phonex === ""
    ) {
      setshowe(true);
    } else {
      setshowe(false);
      setpage(2);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (state.checkedA) {
      setshowerr2(false);
      await Axios.post(`${process.env.REACT_APP_SERVER}/sendregister`, {
        businessx: businessx,
        rcx: rcx,
        emailx: emailx,
        phonex: phonex,
        addressx: addressx,
      }).then((res) => {
        if (res.data.status === 200) {
          setpage(3);
        }
      });
    } else {
      setshowerr2(true);
    }
  };

  return (
    <div className="su_jdj2">
      {page1 === 1 && (
        <>
          <div className="su_j83"> * All fields are required </div>
          <div>
            <TextField
              className={classes.tt}
              id="businessx"
              label="Name of Business"
              type="text"
              variant="outlined"
              onChange={changeHandler} 
            />
          </div>
          <div>
            <TextField
              className={classes.tt}
              id="rcx"
              label="RC/BN Number"
              type="text"
              variant="outlined"
              onChange={changeHandler} 
            />
          </div>
          <div>
            <TextField
              className={classes.tt}
              id="emailx"
              label="Email"
              type="text"
              variant="outlined"
              error={emailerror}
              onChange={changeHandler} 
            />
          </div>
          <div>
            <TextField
              className={classes.tt}
              id="phonex"
              label="Phone Number"
              type="text"
              variant="outlined"
              onChange={changeHandler} 
            />
          </div>
          {showe && (
            <div className="su_dq88">
              {" "}
              Email invalid or fields incorrectly filled
            </div>
          )}
          <div className="su_jd28">
            <Button className={classes.btn} onClick={nextHandle}>
              {" "}
              Next{" "}
            </Button>
          </div>
        </>
      )}

      {page1 === 2 && (
        <>
          <div>
            {showerr2 && (
              <div className="su_sactu2">
                {" "}
                You have to accept terms and conditions{" "}
              </div>
            )}
          </div>
          <div>
            <TextField
              className={classes.tt}
              id="Addressx"
              label="Address of Business*"
              type="text"
              variant="outlined"
              onChange={changeHandler} 
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={state.checkedA}
                  onChange={handleChange}
                  name="checkedA" 
                />
              }
              label="I agree to the terms and conditions"
            />
          </div>
          <div className="su_tandc">
            {" "}
            <a href="#">Terms and conditions </a>
          </div>

          <div className="su_jd28">
            <Button className={classes.btn} onClick={submitHandler}>
              {" "}
              Submit{" "}
            </Button>
          </div>
        </>
      )}

      {page1 === 3 && (
        <>
          <div>
            Thank you for registering. You will recieve your login credientials
            in your email once application is approved.
          </div>

          <div className="su_jd28">
            <Button className={classes.btn} onClick={close}>
              {" "}
              Close{" "}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Signup;
