import React from "react";
import CreateCoupon from "./CreateCoupon";

const Coupon = () => {
  return (
    <div className="">
      <CreateCoupon />
    </div>
  );
};

export default Coupon;
