import React,{useState,useEffect} from "react";
import TextField from "@material-ui/core/TextField"
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert'
import firebase from '../Firebase'
import Buttons from '../Components/Button'
import Axios from 'axios'


import "./SendMessage.css";


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  mtxt:{
    width : "100%",
    marginTop:"25px",
    marginBottom: "15px"
  },
  txt:{
    width: "100%"
  },
  btn: {
    textAlign:"center"
  }
}));

const SendMessage = () => {
  const classes = useStyles();
  const [msg,setmsg] =useState('')
  const [subject,setsubject] =useState('')
  const [sent,setsent] = useState(false)
  const [data,setdata] = useState()

  useEffect(() => {
    try {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setdata(user);
        } else {
          setdata();
        }
      });
    } catch (e) {
      if (e) {
        console.log(e);
      }
    }
  }, [ ]);



const changeHandler = (e) => {
  let id = e.target.id
  let value = e.target.value

  switch(id){
    case "subject": 
    setsubject(value)
    break;
    case "msg": 
    setmsg(value)
    break;
  }

}

const submitHandler = async (e)=> {
  e.preventDefault()
  if(subject === "" || msg === ""){

  }
  else{
     await Axios.post(`${process.env.REACT_APP_SERVER}/sendmessage`,{
       subject:subject,
       msg:msg,
       email:data.email
     }).then(res=> {
       if(res){
              setsent(true)
       }
     }).catch(e=> {
       if(e){
         console.log(e)
       }
     })
  }


}
   
  
  return <div>

  {sent? <div><Alert severity="success">Message Sent </Alert>  </div> :<form className={classes.root} noValidate autoComplete="off" onSubmit = {submitHandler}>
    <div>
    <TextField id="standard-basic" label="Subject"  className = {classes.txt} defaultValue = {subject } id = "subject"  onChange = {changeHandler}  /> 
    </div>
      <div>
      <TextField className = {classes.mtxt}
          id="outlined-multiline-static"
          label="Message"
          multiline
          rows={10} 
          defaultValue={msg}
          variant="outlined"
          id = "msg"
          onChange = {changeHandler}
        />

      </div>
      <div className = {classes.btn}> 
        <Buttons onClick = {submitHandler}  name = "Send Message" id ="SendMessage"  />
      </div>
   
    </form>  }
    
  </div>;
};

export default SendMessage;
