import React, { useRef, useState, useEffect } from "react";
import firebase from "../../Firebase";
import uniqueKeygen from "unique-keygen";
import Couponpdf from "./Couponpdf";
import Navbar from "../../Components/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Buttons from "../../Components/Button";

import "./CreateCoupon.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const CreateCoupon = () => {
  const classes = useStyles();
  const beneRef = useRef();
  const comRef = useRef();
  const [data, setdata] = useState();
  const [bene, setbene] = useState("");
  const [commission, setcommission] = useState(0);
  const [allocate, setallocate] = useState(0);
  const [err, seterr] = useState(false);
  const [errmsg, seterrmsg] = useState("");
  const [ui, setui] = useState("");
  const db = firebase.firestore();

  useEffect(() => {
    try {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setdata(user);
          db.collection("Planers")
            .doc(user.email)
            .get()
            .then((doc) => {
              if (doc.exists) {
                setdata(doc.data());
                setcommission(doc.data().commission);
              } else {
                console.log("No doc present");
              }
            });
        } else {
        }
      });
    } catch (e) {
      if (e) {
        console.log(e);
      }
    }
  }, []);

  const changeHandler = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    if (id === "bene") {
      setbene(value);
    } else if (id === "allocate") {
      if (isNaN(value)) {
      } else {
        setallocate(value);
        setcommission(parseInt(data.commission) - parseInt(value));
      }
    }
  };

  const submitHandle = (e) => {
    e.preventDefault();
    if (commission < 0) {
      seterrmsg("You cannot allocate more than your approved discount limit");
      seterr(true);
    } else {
      let UID = uniqueKeygen(9).toUpperCase();
      setui(UID);
      db.collection("Coupons")
        .doc(UID)
        .set({
          Bene: bene,
          Commission: commission,
          Allocate: allocate,
          Planner: data.business,
          UID: UID,
          used: false,
          pemail: data.email,
        })
        .then((res) => {
          if (res) {
            console.log("document saved");
          }
        })
        .catch((e) => {
          if (e) {
            console.log(e);
          }
        });
    }
  };

  const focus = (e) => {
    document.getElementById(e.target.id).select();
  };

  if (data) {
    if (data.role === "admin" || data.role === "planner") {
      return (
        <div>
          <Navbar />
          <div className="container cc_die88">
            {err && (
              <div className={classes.root}>
                <Alert severity="error">{errmsg}</Alert>
              </div>
            )}

            <div className="cc_du83">Create Coupon Page</div>
            <div className="cc_dji1">
              <form onSubmit={submitHandle}>
                <div className="d-flex">
                  <div className="cc_jdu1oo">
                    <TextField
                      id="business"
                      variant="outlined"
                      label="Business Name:"
                      value={data ? data.business : "loading"}
                      disabled
                    />
                  </div>
                  <div className="cc_jdu1oo">
                    <TextField
                      id="commission1"
                      variant="outlined"
                      label=" Approved Discount Limit (%):"
                      value={data ? data.commission : "loading"}
                      disabled
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div>
                    {/* <label htmlFor="bene">Beneficiary Name:</label> */}
                  </div>
                  <div className="cc_jdu1oo">
                    <TextField
                      id="bene"
                      variant="outlined"
                      label="Name of Beneficiary:"
                      onChange={changeHandler}
                      value={bene}
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div></div>
                  <div className="cc_jdu1oo">
                    <TextField
                      id="commission"
                      variant="outlined"
                      label="You Earn (%):"
                      value={data ? `${commission}` : "loading"}
                      disabled
                    />
                  </div>
                  <div className="cc_jdu1oo">
                    <TextField
                      id="allocate"
                      variant="outlined"
                      label="Allocate Discount (%)"
                      onChange={changeHandler}
                      value={`${allocate}`}
                      onFocus={focus}
                    />
                  </div>
                </div>
                <div className="cc_djoe333">
                  <Buttons
                    name="Generate Coupon"
                    onClick={submitHandle}
                    id="Generate"
                  />
                </div>
              </form>
            </div>
            <div className="cc_did33">
              <Couponpdf
                data={data}
                bene={bene}
                ui={ui}
                commission={allocate}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return <div> You are not authorized to view this page</div>;
    }
  } else {
    return <div> Loading</div>;
  }
};

export default CreateCoupon;
