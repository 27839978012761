import React, { useEffect, useState } from "react";
import firebase from "../../Firebase";
import ReactToPrint from "react-to-print";
import Couponmain from "./CouponSet/Couponmain";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Badge from "@material-ui/core/Badge";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

import "./ViewCoupon.css";
import Buttons from "../../Components/Button";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  badd: {
    // marginTop: "-10px"
  },
  vc_djo22: {
    marginTop: "10px",
  },
  search: {
    width: "50%",
  },
});

const ViewCoupon = () => {
  const classes = useStyles();
  const db = firebase.firestore();
  const tref = React.createRef();
  const [data, setdata] = useState();
  const [data1, setdata1] = useState();
  const [coupons, setcoupons] = useState();
  const [xbene, setxbene] = useState("");
  const [xcouponcode, setxcouponcode] = useState("");
  const [xcommission, setxcommission] = useState("");
  const [xplanner, setxplanner] = useState("");
  const [lastview, setlastview] = useState("");
  const [firstview, setfirstview] = useState("");
  const [commx, setcommx] = useState(0);
  const [paidx, setpaidx] = useState(0);

  useEffect(() => {
    try {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setdata(user);
          db.collection("Planers")
            .doc(user.email)
            .get()
            .then((res) => {
              if (res) {
                setdata1(res.data());
                db.collection("Coupons")
                  .where("Planner", "==", res.data().business)
                  .get()
                  .then((res) => {
                    if (res) {
                      let dd = [];
                      res.forEach((doc) => {
                        dd.push(doc.data());
                      });
                      let inc = 0;
                      let paidx = 0;
                      dd.map((x) => {
                        if (x.Commamount) {
                          inc = inc + parseInt(x.Commamount);
                          if (x.paid === true) {
                            paidx = paidx + parseInt(x.Commamount);
                          }
                        }
                        setcommx(inc);
                        setpaidx(paidx);

                        return;
                      });
                    }
                  });
                db.collection("Coupons")
                  .orderBy("Bene")
                  .where("Planner", "==", res.data().business)
                  .limit(10)
                  .get()
                  .then((res) => {
                    setlastview(res.docs[res.docs.length - 1]);
                    setfirstview(res.docs[0]);

                    let dd = [];
                    res.forEach((doc) => {
                      dd.push(doc.data());
                    });
                    setcoupons(dd);
                  });
              }
            });
        }
      });
    } catch (e) {
      if (e) {
        console.log(e);
      }
    }
  }, []);

  const ajaxsearch = async (e) => {
    let value = e.target.value;
    console.log(value);
    db.collection("Coupons")
      .where("Planner", "==", data1.business)
      .get()
      .then((res) => {
        setlastview(res.docs[res.docs.length - 1]);
        setfirstview(res.docs[0]);

        let dd = [];
        res.forEach((doc) => {
          if (doc.data().Bene.includes(value)) {
            dd.push(doc.data());
          }
        });
        setcoupons(dd);
      });
  };

  const gettotalcomm = async () => {
    if (data1) {
    }
  };

  const next = (e) => {
    e.preventDefault();
    try {
      db.collection("Coupons")
        .orderBy("Bene")
        .where("Planner", "==", data1.business)
        .startAfter(lastview)
        .limit(10)
        .get()
        .then((res) => {
          if (res) {
            if (res.docs.length >= 1) {
              setlastview(res.docs[res.docs.length - 1]);
              setfirstview(res.docs[0]);
              let dd = [];
              res.forEach((doc) => {
                dd.push(doc.data());
              });
              setcoupons(dd);
            }
          }
        });
      //   }
    } catch (e) {
      if (e) {
        console.log(e);
      }
    }
  };
  const back = (e) => {
    e.preventDefault();
    try {
      db.collection("Coupons")
        .orderBy("Bene")
        .where("Planner", "==", data1.business)
        .endBefore(firstview)
        .limitToLast(10)
        .get()
        .then((res) => {
          if (res.docs.length >= 1) {
            setlastview(res.docs[res.docs.length - 1]);
            setfirstview(res.docs[0]);
            let dd = [];
            res.forEach((doc) => {
              dd.push(doc.data());
            });
            setcoupons(dd);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  if (data1) {
    if (data1.role === "admin" || data1.role === "planner") {
      return (
        <>
          <Navbar />
          <div className="container vc_djl2">
            <div className="cc_du83  ">
              <div>
                {" "}
                View Coupon Page - {data1 && data1.business}{" "}
                <span className="vc_dj3w">
                  {" "}
                  Total earned:{" "}
                  <strong>
                    {" "}
                    NGN{commx
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  </strong>
                  <div className="vc_djkjo22">
                    {" "}
                    Total paid: NGN
                    {paidx
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  </div>
                </span>
              </div>
              <div> </div>
            </div>{" "}
            <div>
              <div className="vc_dklwi44">
                {" "}
                <InputLabel htmlFor="input-with-icon-adornment">
                  Search with Beneficiary name{" "}
                  <span className="vc_span"> - Case sensitive</span>
                </InputLabel>
                <Input
                  className={classes.search}
                  onChange={ajaxsearch}
                  id="input-with-icon-adornment"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />{" "}
              </div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Beneficiary</TableCell>
                      <TableCell align="centre">Coupon code</TableCell>
                      <TableCell align="centre">
                        Commission Approved %
                      </TableCell>
                      <TableCell align="centre">Commission Earned %</TableCell>
                      <TableCell align="centre">Discount Given %</TableCell>
                      <TableCell align="centre">Invoice Number</TableCell>
                      <TableCell align="centre">Invoice Amount</TableCell>
                      <TableCell align="centre">
                        {" "}
                        Amount Earned (NGN){" "}
                      </TableCell>

                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {coupons ? (
                      coupons.map((res) => {
                        return (
                          <TableRow key={res.UID}>
                            <TableCell component="th" scope="row">
                              {res.Bene}
                            </TableCell>
                            <TableCell>{res.UID}</TableCell>
                            <TableCell>{data1.commission}</TableCell>
                            <TableCell>
                              {parseInt(data1.commission) -
                                parseInt(res.Commission)}
                            </TableCell>
                            <TableCell>{res.Commission}</TableCell>
                            <TableCell>{res.Invoicenumber}</TableCell>
                            <TableCell>{res.Amount}</TableCell>

                            {res.paid ? (
                              <TableCell>
                                {" "}
                                <Badge
                                  badgeContent={"paid"}
                                  color="secondary"
                                  className={classes.badd}
                                >
                                  <div className={classes.vc_djo22}>
                                    {res.Commamount}
                                  </div>
                                </Badge>{" "}
                              </TableCell>
                            ) : (
                              <TableCell>{res.Commamount}</TableCell>
                            )}
                            {res.Bene && (
                              <div
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => {
                                  setxbene(res.Bene);
                                  setxcommission(res.Commission);
                                  setxcouponcode(res.UID);
                                  setxplanner(res.Planner);
                                }}
                                className="vc_dj2"
                              >
                                <Buttons name="View" id="View" />
                              </div>
                            )}
                          </TableRow>
                        );
                      })
                    ) : (
                      <div> Loading</div>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog " role="document">
                  <div class="modal-content vc_djk3modal">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        <span className="vc_sjr22">Coupon for {xbene} </span>
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div ref={tref}>
                        <Couponmain
                          commission={xcommission}
                          bene={xbene}
                          ui={xcouponcode}
                          business={xplanner}
                        />
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div>
                        {" "}
                        <ReactToPrint
                          trigger={() => <button>Print</button>}
                          content={() => tref.current}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", marginTop: "10px" }}>
                <div>
                  <Buttons name="Back" onClick={back} id="Back" />
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <Buttons name="Next" onClick={next} id="Next" />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      );
    } else {
      return <div> You are not authorized to view this page</div>;
    }
  } else {
    return <div> Loading </div>;
  }
};

export default ViewCoupon;
