import React from "react";
import Couponmain from "./CouponSet/Couponmain"; 
import ReactToPdf from "react-to-pdf";
import ReactToPrint from "react-to-print"; 
import Buttons from "../../Components/Button";
import "./Couponpdf.css";

const Couponpdf = (props) => {
  const tref = React.createRef();

  return (
    <div>
      <div ref={tref}>
        <Couponmain
          data={props.data}
          bene={props.bene}
          ui={props.ui}
          commission={props.commission}
        />
      </div>

      {props.ui && (
        <div className="d-flex mt-2">
          {" "}
          <div>
            {" "}
            <ReactToPdf
              targetRef={tref}
              filename={`${props.ui}.pdf`}
              x={25}
              y={55}
            >
              {({ toPdf }) => (
                <div >
                  <Buttons name="Download" onClick={toPdf} id = "Download" />{" "}
                </div>
              )}
            </ReactToPdf>
          </div>
          <div className="cp_jdj2">
            {" "}
            <ReactToPrint
              trigger={() => (
                <div>
                  {" "}
                  <Buttons name="Print"  id = "Print" />{" "}
                </div>
              )}
              content={() => tref.current}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Couponpdf;
