import React, { useEffect, useState, useRef } from "react";
import TextField from "@material-ui/core/Textfield"
import Alert from "@material-ui/lab/Alert"
import Buttons from "../../../Components/Button"
import firebase from "../../../Firebase";
import { makeStyles } from "@material-ui/core/styles";
import "./ActiveCoupon.css"

const useStyles = makeStyles({
  tt: {
    marginRight:"25px",
    marginTop:"10px"
  },
});

const ActiveCoupon = (props) => {
  const classes = useStyles()
  const db = firebase.firestore();
  const [invoicenumber,setinvoicenumber] = useState('')
  const [invoiceamount,setinvoiceamount] =useState('')
  const [done,setdone] = useState(false)
  const [msg, setmsg] = useState();
  const [showmsg, setshowmsg] = useState(false);

  useEffect(() => {
    setinvoiceamount("")
    setinvoicenumber("")  
  }, [props.couponCode]);

  const submitHandler = (e) => {
    e.preventDefault();
    if(invoiceamount === ""  || invoicenumber === "") {

    }
    else{
      try {
        db.collection("Coupons")
          .doc(props.couponCode)
          .set(
            {
              Amount: parseInt(invoiceamount),
              Commamount: parseInt(
                invoiceamount * (props.commission / 100)
              ),
              Invoicenumber: invoicenumber,
              used: true,
              paid:false
            },
            { merge: true }
          )
          .then(() => { 
            setmsg("Data Updated");
            setshowmsg(true);
            setdone(true)
            props.refreshx();
          })
          .catch((e) => {
            if (e) {
              console.log(e);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
 
  };

  const changeHandler = (e) => {
    let id = e.target.id
    let value = e.target.value
    switch (id){
      case "invoicenumber":
        setinvoicenumber(value)
        break
        case "invoiceamount":
          setinvoiceamount(value)
          break

          default:
            break
    }
  }

  return (
    <div className="container">
    {done? <Alert severity="success"> Coupon updated! </Alert>    : <div> 

        <form onSubmit={submitHandler}>
         
          <div className = "ac_jd33">
           <TextField variant="outlined" value={props.bene} label="Beneficiary" className = {classes.tt} disabled />
           <TextField variant="outlined" value={props.couponCode} label="Coupon Code" className = {classes.tt} disabled />
           <TextField variant="outlined" value={props.commission} label="Commission %" className = {classes.tt} disabled/>
         </div> 
        
         <div className = "ac_jd33">
           
           <TextField variant="outlined" id="invoicenumber" label="Invoice Number" className = {classes.tt} onChange = {changeHandler}/>
           <TextField variant="outlined" id="invoiceamount" label="Invoice Amount" className = {classes.tt} onChange = {changeHandler}/>
         </div>

         <div>
           <Buttons name = "Submit" id ="Save" onClick = {submitHandler} /> 
         </div>

           
        </form>
      </div>  }
      
    </div>
  );
};

export default ActiveCoupon;
