import React from "react";
import "./Couponmain.css";

const Cs1 = (props) => {
  return (
    <>
      <div>
        <img src="\imgs\logo_trans.png" width="80px" />
      </div>
      <div className="cs1_du3">
        <div className="cs1_dju2">
          <span className="cs1_4urj">
            {props.business.business || props.business}
          </span>
        </div>
        <div className="cs1_dju3">
          <span className="cs1_4urj"> {props.bene} </span>
        </div>
      </div>
      <div className="cs1_diw9">
        {" "}
        {props.commission || props.commission}% Off
      </div>
    </>
  );
};

export default Cs1;
