import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../../Components/Navbar"
import firebase from "../../../Firebase"; 
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Badge from '@material-ui/core/Badge';
import Paper from "@material-ui/core/Paper";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import Buttons from "../../../Components/Button" 


import "./CompletedCoupon.css"
import Sure from "./Sure";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  vc_djo22:{
    marginTop:"10px"
  },
  search:{
    width:"50%"
  }
});

const CompletedCoupon = () => {
  const classes = useStyles();
  const db = firebase.firestore();
  const [data, setdata] = useState();
  const [coupons, setcoupons] = useState();
  const [refresh, setrefresh] = useState(true);
  const [xbene, setxbene] = useState("");
  const [planner, setplanner] = useState("");
  const [pemail, setpemail] = useState("");
  const [xcouponcode, setxcouponcode] = useState("");
  const [xcommission, setxcommission] = useState("");
  const [data1, setdata1] = useState();
  const [lastview, setlastview] = useState("");
  const [firstview, setfirstview] = useState("");
  const tref = React.createRef();

  useEffect(() => {
    try {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setdata(user);
          db.collection("Planers")
          .doc(user.email)
          .get()
          .then((res) => {
            if (res) {
              setdata1(res.data());
            }
          })
          db.collection("Coupons").orderBy("Bene")
            .where("used", "==", true)
            .limit(10)
            .get()
            .then((res) => {
              setlastview(res.docs[res.docs.length - 1]);
              setfirstview(res.docs[0]);

              let dd = [];
              res.forEach((doc) => {
                dd.push(doc.data());
              }); 
              setcoupons(dd);
            });
        }
      });
    } catch (e) {
      if (e) {
        console.log(e);
      }
    }
  }, [refresh]);

  const closeModal = () => {
    // document.getElementById('exampleModal').classList.remove('show');
    
    // document.getElementById('exampleModal').style.display = "none";
    document.getElementById('exampleModal').setAttribute('aria-hidden', 'true');
    
  }

  const refreshx = () => {
    setrefresh(!refresh);
  };

  const ajaxsearch = async (e) => {
    let value = e.target.value 
     db.collection("Coupons")
                   .get()
                   .then((res) => {
                     setlastview(res.docs[res.docs.length - 1]);
                     setfirstview(res.docs[0]);
 
                     let dd = [];
                     res.forEach((doc) => {
                       if(doc.data().Bene.includes(value)){
                         dd.push(doc.data());
                       }
                       
                     }); 
                     setcoupons(dd);
                   });
 
   }

  const next = (e) => {
    e.preventDefault();
    try { 
      db.collection("Coupons")
        .orderBy("Bene").where("used", "==", true)
        .startAfter(lastview)
        .limit(10)
        .get()
        .then((res) => {
          if (res) {
            if (res.docs.length >= 1) {
              setlastview(res.docs[res.docs.length - 1]);
              setfirstview(res.docs[0]);
              let dd = [];
              res.forEach((doc) => {
                dd.push(doc.data());
              });
              setcoupons(dd);
            }
          }
        });
      //   }
    } catch (e) {
      if (e) {
        console.log(e);
      }
    }
  };
  const back = (e) => {
    e.preventDefault();
    try {
      db.collection("Coupons")
        .orderBy("Bene").where("used", "==", true)
        .endBefore(firstview)
        .limitToLast(10)
        .get()
        .then((res) => {
          if (res.docs.length >= 1) {
            setlastview(res.docs[res.docs.length - 1]);
            setfirstview(res.docs[0]);
            let dd = [];
            res.forEach((doc) => {
              dd.push(doc.data());
            });
            setcoupons(dd);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  if(data1){
    if( data1.role === "accounts" || data1.role === "admin" || data1.role === "staff" ){

        return (
            <>
            <Navbar />
            <div className="container cs_d88j">
              <div className="cc_du83">Completed Coupons</div>
              <div>
        
              <div className = "cs_djjo333" >   <InputLabel htmlFor="input-with-icon-adornment" >Search with Beneficiary name <span className = "vc_span" > -  Case sensitive</span></InputLabel>
        <Input
        className = {classes.search}
        onChange = {ajaxsearch}
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          
          }
        /> </div> 
              <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {/* <TableCell>Beneficiary</TableCell> */}
                          <TableCell align="centre">Beneficiary</TableCell>
                          <TableCell align="centre">Coupon Code </TableCell>
                          <TableCell align="centre">Commission %</TableCell>
                          <TableCell align="centre">Invoice Amount</TableCell>
                          <TableCell align="centre">Amount Earned</TableCell>
                          <TableCell align="centre">Invoice Number</TableCell> 
                          <TableCell align="centre">Planner</TableCell> 
        
                          <TableCell align="right">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
        
         
                    {coupons ?
                      coupons.map((res) => {
                        return (
                          <TableRow key={res.UID}>
                                <TableCell component="th" scope="row">
                                  {res.Bene}
                                </TableCell>
                                <TableCell align="centre">{res.UID}</TableCell>
                                <TableCell align="centre">{res.Commission}</TableCell>
                                <TableCell align="centre">{res.Amount}</TableCell>
                                {res.paid ?<TableCell> <Badge badgeContent={"paid"} color="secondary" className = {classes.badd}>
         <div className = {classes.vc_djo22}>{res.Commamount}</div> 
        </Badge>  </TableCell> : <TableCell >{res.Commamount}</TableCell> }
                                <TableCell align="centre">
                                  {res.Invoicenumber}
                                </TableCell>
                                <TableCell align="centre">
                                  {res.Planner}
                                </TableCell>
                                
                                {data1.role === "accounts" || data1.role === "admin" ? res.paid? <Buttons name="Paid" color="classes.success"  />   : res.Bene && (
                                  <div
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => {
                                      setxbene(res.Bene);
                                  setxcommission(res.Commamount);
                                  setxcouponcode(res.UID);
                                  setplanner(res.Planner)
                                  setpemail(res.pemail)
                                    }}
                                    className="vc_dj2"
                                  >
                                    <Buttons name="Pay Commission" id = "View" />
                                  </div>
                                ) : null  }
                               
                              </TableRow>
                            );
                          })
                         : (
                          <div> Loading</div>
                        )}
                        </TableBody>
                    </Table>
                  </TableContainer>
                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog " role="document">
                      <div className="modal-content vc_djk3modals">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            <span className="vc_sjr22">Commission for {planner} </span>
                            <span className = "comc_dj2o3"> <LabelImportantIcon/> Transaction for {xbene} </span>
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div ref={tref}>
                          <Sure  
                                        refreshx={refreshx}
                                        bene={xbene}
                                        couponCode={xcouponcode}
                                        commission={xcommission}
                                        planner = {planner} 
                                        pemail = {pemail}
                                      />
                          </div>
                        </div>
                         
                      </div>
                    </div>
                  </div>
        
        
        
        
                      <div style={{ display: "flex", marginTop: "10px" }}>
                    <div >
                      <Buttons name="Back" onClick={back} id= "Back" />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <Buttons name="Next" onClick={next} id = "Next" />
                    </div>
                    
                  </div>
              </div>
            </div>
            </>
          );
     }
     else{
         return(
             <div> You are not autorized to view this page</div>
         )
     }
  }
  else{
      return(
<div> Loading</div>
      )
      
  }
  

  
};

export default CompletedCoupon;
