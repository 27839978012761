import React, { useState, useEffect } from "react";
import "./Footer.css";

const Footer = () => {
  const [dd, setdd] = useState(new Date());

  useEffect(() => {}, []);
  return (
    <>
      <div className="ft_duranti">
        <img src="\imgs\ft.svg" alt="Wedding rings" className="ft_duw9" />
      </div>

      <div className="ft_dk39">
        <div>© Delphi Metals {dd.getFullYear()}. All Rights Reserved. Powered by <a href="http://booleandev.com"> Booleandev</a> </div>
      </div>
    </>
  );
};

export default Footer;
