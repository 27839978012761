import React from "react";
import "./Couponmain.css";
import Cs1 from "./Cs1";
import Cs2 from "./Cs2";

const Couponmain = (props) => {
  return (
    <div className="cm_idj3">
      {props.bene && (
        <Cs1
          business={props.data || props.business}
          bene={props.bene}
          commission={props.commission}
        />
      )}
      {props.ui && <Cs2 business={props.data} ui={props.ui} />}
    </div>
  );
};

export default Couponmain;
