import React,{useEffect,useState} from 'react';
import Buttons from '../../../Components/Button';
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation';
import Alert from "@material-ui/lab/Alert"
import firebase from "../../../Firebase"
import Axios from 'axios'

import "./Sure.css"


const Sure = ({refreshx,
    bene,
    couponCode,
    commission,planner,pemail}) => {

        const db = firebase.firestore()
        const [data,setdata] = useState() 
        const [done,setdone] = useState(false) 
        
        useEffect(()=> {
             
         {pemail &&   db.collection("Planers").doc(pemail).get().then(res=>{
                
                   setdata(res.data())
                   setdone(false)
              
           }).catch(e=> {
               if(e){
                   console.log(e)
               }
           })
        }
            
        },[pemail])


        const clickyes = async (e) => {
            e.preventDefault()
            {data && 
            await db.collection("Coupons").doc(couponCode).set({
              paid:true
            },{merge:true}).then(res=> {
                
                Axios.post(`${process.env.REACT_APP_SERVER}/sendpaid`, {data:data,commamount:commission }).then(res=> {
                    if(res){
                        console.log("Email sent")
                    }
                }).catch(e=> {
                    if(e){
                        console.log(e)
                    }
                })
                
                refreshx()
                setdone(true)
            })
        }

        }

        
    return (
        <div>
        {done ? <Alert severity="success">Commission paid</Alert>   :   <>  <div className = "su_txt">
                Are you sure you want to pay? 
                <div className="su_dol24r8fc"> 
                  <PermDeviceInformationIcon /> This operation cannot be reversed!
                </div>
            </div>
            <div className = "d-flex su_kke39"> 
            <div className = "su_jbtn">
            <Buttons name ="Yes" onClick = {clickyes} />
            </div>
             
           
            </div> </> }
           
            
        </div>
    );
}

export default Sure;
