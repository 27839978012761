import React from "react";
import Navbar from "../../Components/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import Footer from "../../Components/Footer";
import "./Home.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  btn: {
    backgroundColor: "#0A0908 !important",
    color: "#F2F4F3 !important",
    width: "200px",
    height: "50px",
  },
}));

const Home = () => {
  const classes = useStyles();

  const BigButton = ({ cat }) => {
    return (
      <div>
        <Button className={classes.btn}> {cat}</Button>
      </div>
    );
  };

  return (
    <div>
      <Navbar />
      <div className="container hm_jdhe82">
        <div className="hm_jr39">
          <div className="hm_section1">
            <div className="hm_ei321">
              <h1>Specially Developed</h1>{" "}
              <img src="\imgs\devops.svg" className="hm_dik32" />{" "}
              <h2>for our Wedding Planners </h2>
            </div>
          </div>
          <div className="hm_section2">
            <div>
              <img
                src="\imgs\dd.svg"
                className="hm_dju32"
                alt=" Tools for our wedding planners"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
