import React, { useRef, useContext, useState } from "react";
import EmailValidator from "email-validator";
import Navbar from "../../Components/Navbar";
import Buttons from "../../Components/Button";
import Footer from "../../Components/Footer";
import firebase from "../../Firebase";
import Axios from "axios";

import "./Createuser.css";

const Createuser = () => {
  const [show, setshow] = useState(false);
  const [err, seterr] = useState(false);
  const [errm, seterrm] = useState("");
  const username = useRef(null);
  const password = useRef(null);
  const commission = useRef(null);
  const business = useRef("");
  const rcnumb = useRef("");
  const email = useRef("");
  const address = useRef("");
  const phone = useRef("");
  const role = useRef("");

  let db = firebase.firestore();
  

  const submitHandler = async (e) => {
    e.preventDefault();
    if (EmailValidator.validate(email.current.value)) {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(
          username.current.value,
          password.current.value
        )
        .then(async (userCredential) => {
          // Signed in
          var user = userCredential.user;
          seterr(false);
          if (user) {
            setshow(true);
            await db
              .collection("Planers")
              .doc(email.current.value)
              .set({
                email: email.current.value,
                username: email.current.value,
                business: business.current.value,
                rcnumb: rcnumb.current.value,
                commission: parseInt(commission.current.value),
                address: address.current.value,
                phone: phone.current.value,
                role:role.current.value
              })
              .then((docRef) => { 
              })
              .catch((error) => {
                console.error("Error adding document: ", error);
              });

            await Axios.post(`${process.env.REACT_APP_SERVER}/senddetails`, {
              businessx: business.current.value,
              emailx: email.current.value,
              commissionx: commission.current.value,
              passwordx: password.current.value,
            })
              .then((res) => {
                if (res) {
                  console.log("done");
                  password.current.value = "";
                  username.current.value = "";
                  business.current.value = "";
                  rcnumb.current.value = "";
                  email.current.value = "";
                  address.current.value = "";
                  commission.current.value = "";
                  phone.current.value = " ";
                  role.current.value =""
                }
              })
              .catch((e) => {
                if (e) {
                  console.log(e);
                }
              });
          }
          // ...
        })
        .catch((error) => {
          console.log(error);
          seterr(true);
          seterrm(error.message);
          var errorMessage = error.message;
          // ..
        });
    }
  };

 

  return (
    <>
      <Navbar />
      <div className="cu_dj3 container">
        <div> 
          <div className="cu_dsl3">Create user</div>
          <div>{show ? "User Created" : null}</div>
          <div>{err ? errm : null}</div>
          <div className="cu_skl3">
            {" "}
            <input type="text" placeholder="Username" ref={username} />{" "}
          </div>
          <div className="cu_skl3">
            {" "}
            <input type="password" placeholder="Password" ref={password} />{" "}
          </div>
          <div className="cu_skl3">
            {" "}
            <input type="text" placeholder="Email" ref={email} />{" "}
          </div>
          <div className="cu_skl3">
            {" "}
            <input
              type="text"
              placeholder="Business Name"
              ref={business}
            />{" "}
          </div>
          <div className="cu_skl3">
            {" "}
            <input type="text" placeholder="Address" ref={address} />{" "}
          </div>
          <div className="cu_skl3">
            {" "}
            <input type="text" placeholder="Phone number" ref={phone} />{" "}
          </div>
          <div className="cu_skl3">
            {" "}
            <input type="text" placeholder="RC Number" ref={rcnumb} />{" "}
          </div>

          <div className="cu_skl3">
            {" "}
            <input
              type="text"
              placeholder="Commission Approved"
              ref={commission}
            />{" "}
          </div>
          <div className="cu_skl3">
        <select name="Role" id="role" ref = {role}  >
  <option value="planner">Planner</option>
  <option value="staff">Staff</option> 
  <option value="accounts">Accounts</option> 
  <option value="admin">Admin</option> 
</select>
            {" "}
            
          </div>
          <div onClick={submitHandler}>
            <Buttons name="Save" />
          </div>
        </div>
        <div className="cu_img34">
          {" "}
          <img
            src="\imgs\createuser.svg"
            alt="create user"
            className="cu_svg"
          />{" "}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Createuser;
