import React, { useEffect, useState, useRef } from "react";
import Navbar from "./Navbar";
import firebase from "../Firebase";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Modal from "@material-ui/core/Modal";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Fade from "@material-ui/core/Fade"; 

import "./Profile.css";
import Footer from "./Footer";
import Buttons from "./Button";
import axios from "axios";
import Changepwd from "../Pages/Login/Changepwd";
import SendMessage from "./SendMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  alee: {
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  ddd: {
    position: "absolute",
    top: "30%",
    left: "50%",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-100px",
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Profile = () => {
  const classes = useStyles();
  const [data, setdata] = useState();
  const [picture, setpicture] = useState([]);
  const [accountnumber, setaccountnumber] = useState("");
  const [accountname, setaccountname] = useState("");
  const [bankname, setbankname] = useState("");
  const [open, setopen] = useState(false);
  const [bankalert, setbankalert] = useState(false);
  const [openpwd, setopenpwd] = useState(false);
  const [certalert, setcertalert] = useState(false);
  const [openmsg, setopenmsg] = useState(false);

  const busName = useRef();
  const db = firebase.firestore();

  const onDrop = (pictures) => {
    setpicture([...picture, pictures]);
  };

  useEffect(() => {
    try {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setdata(user);
          db.collection("Planers")
            .doc(user.email)
            .get()
            .then((doc) => {
              if (doc.exists) {
                setdata(doc.data());
                setaccountnumber(doc.data() ? doc.data().accountnumber : "");
                setaccountname(doc.data() ? doc.data().accountname : "");
                setbankname(doc.data() ? doc.data().bankname : "");
              } else {
                console.log("No doc present");
              }
            });
        } else {
        }
      });
    } catch (e) {
      if (e) {
        console.log(e);
      }
    }
  }, []);

  const changehandlerBank = (e) => {
    let id = e.target.id;
    let value = e.target.value;

    switch (id) {
      case "accountnumber":
        setaccountnumber(value);
        break;
      case "accountname":
        setaccountname(value);
        break;
      case "bankname":
        setbankname(value);
        break;
      default:
        break;
    }
  };

  const handleOpenMsg = () => {
    setopenmsg(true);
  };

  const handleCloseMsg = () => {
    setopenmsg(false);
  };
  const handleOpen = (e) => {
    e.preventDefault();
    setopen(true);
  };
  const handleOpenPwd = (e) => {
    e.preventDefault();
    setopenpwd(true);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setopen(false);
    setopenpwd(false);
  };
  const viewcert = (e) => {
    e.preventDefault();
  };

  const submitbankdetails = async (e) => {
    e.preventDefault();
    await db
      .collection("Planers")
      .doc(data.email)
      .set(
        {
          accountname: accountname,
          accountnumber: accountnumber,
          bankname: bankname,
        },
        { merge: true }
      )
      .then(() => {
        setbankalert(true);
      });
  };

  const uploadimage = (e) => {
    setpicture(e.target.files[0]);
 
  };

  const submituploadimage = async (e) => {
    e.preventDefault();
    const fd = new FormData();
    var imagefile = document.querySelector("#file");
    fd.append("image", imagefile.files[0]);

    await axios
      .post(`${process.env.REACT_APP_SERVER}/imagepost`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res) { 
          db.collection("Planers")
            .doc(data.email)
            .set({ certificate: picture.name }, { merge: true });
        }
      })
      .then(() => {
        setcertalert(true);
      });
  };

  return (
    <>
      <Navbar />
      <div className="container pr_kdo2">
        <div className="cc_du83">Profile Page</div>


{data && data.role === "planner" ?  <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <div className="pr_dj22">
                  <TextField
                    id="business"
                    label="Business Name"
                    variant="outlined"
                    value={data ? data.business : "loading"}
                    disabled
                  />
                </div>
                <div className="pr_dj22">
                  <TextField
                    id="rcnumb"
                    label="RC/BN Number"
                    variant="outlined"
                    value={data ? data.rcnumb : "loading"}
                    disabled
                  />
                </div>
                <div className="pr_dj22">
                  <TextField
                    id="commission"
                    label="Commission Approved %"
                    variant="outlined"
                    value={data ? data.commission : "loading"}
                    disabled
                  />
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                {data && data.certificate ? (
                  <>
                    <div>
                      {" "}
                      <div>Certificate of Incorporation Uploaded </div>{" "}
                      <div>
                        <Buttons name="view" onClick={handleOpen} id = "View" />{" "}
                        <div> </div>
                      </div>
                    </div>
                    <Modal
                      className={classes.modal}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                      BackdropComponent={Backdrop}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <Fade in={open}>
                        <div className={classes.paper2}>
                          <h2 id="simple-modal-title"> </h2>
                          <p id="simple-modal-description">
                            <img
                              src={`${process.env.REACT_APP_SERVER}/img/${data.certificate}`}
                              width="450"
                              alt="Certificate of Incorporation"
                            />
                          </p>
                        </div>
                      </Fade>
                    </Modal>
                  </>
                ) : (
                  <>
                    {" "}
                    {certalert ? (
                      <Alert severity="success">
                        Certificate of Incorporation uploaded successfully!
                      </Alert>
                    ) : (
                      <>
                        <div>
                          <CloudUploadIcon />
                          <div className="pr_djj33">
                            {" "}
                            Upload your certificate of Incorporation
                          </div>
                          <input
                            className="pr_djj33"
                            type="file"
                            accept=".pdf,.jpg,.jpeg,.png"
                            name="photo"
                            id="file"
                            onChange={uploadimage}
                          />
                        </div>
                        <div onClick={submituploadimage}>
                          <Buttons name="Upload" />
                        </div>{" "}
                      </>
                    )}
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>    : null}
      

        <div className={classes.root}>
          <Grid container spacing={3}>
          {data && data.role === "planner" ? <Grid item xs={12} sm={6}>
              <Paper className={classes.alee}>
                <div className="pr_dj2a">
                  <div> Banking Details </div>
                  {bankalert && (
                    <div className={classes.alee}>
                      <Alert severity="success">
                        Banking information saved!
                      </Alert>
                    </div>
                  )}

                  <div className="pr_djlwk33">
                    {" "}
                    <span>
                      {" "}
                      Do enter your banking details (ignore if you have done
                      that already)
                    </span>
                  </div>
                  <div className="pr_dj22">
                    <TextField
                      id="accountnumber"
                      label="Account Number"
                      variant="outlined"
                      value={accountnumber}
                      onChange={changehandlerBank}
                    />
                  </div>
                  <div className="pr_dj22">
                    <TextField
                      id="accountname"
                      label="Account Name"
                      variant="outlined"
                      value={accountname}
                      onChange={changehandlerBank}
                    />
                  </div>
                  <div className="pr_dj22">
                    <TextField
                      id="bankname"
                      label="Bank Name"
                      variant="outlined"
                      value={bankname}
                      onChange={changehandlerBank}
                    />
                  </div>
                  <div  >
                    <Buttons name="Save" Onclick = {submitbankdetails} id= "Save"  />
                  </div>
                </div>
              </Paper>
            </Grid>    : null}
            
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <div> Other actions </div>
                <div className="pr_d888">
                  <Buttons name="Change Password" onClick={handleOpenPwd} id = "Password" />
                </div>
                <div>
                  <Modal
                    open={openpwd}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-pwd"
                    aria-describedby="simple-modal-s"
                    className={classes.modal}
                    BackdropComponent={Backdrop}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={openpwd}>
                      <div className={classes.paper2}>
                        <h2 id="simple-modal-pwd"> </h2>
                        <p id="simple-modal-s">
                          <Changepwd />
                        </p>
                      </div>
                    </Fade>
                  </Modal>
                </div>
               {data && data.role === "planner"? <span className="pr_d888">
                  <Buttons name="Message us" onClick={handleOpenMsg} id="SendMessage" />
                </span>   :null}
                
                <Modal
                  className={classes.modal}
                  open={openmsg}
                  onClose={handleCloseMsg}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  BackdropComponent={Backdrop}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={openmsg}>
                    <div className={classes.paper2}>
                      <h2 id="simple-modal-title"> </h2>
                      <p id="simple-modal-description">
                        <SendMessage />
                      </p>
                    </div>
                  </Fade>
                </Modal>
                <div className="pr_d888">
                  <Buttons name="Download Catalogs"  id = "Download" />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
